export const currencyConvertToNumber = (str) => parseFloat(str.replace(/[,]+/g, function(x){ return ""; }).trim());

export const numberConvertToCurrency = (str,fixed = 0) => parseFloat(str).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').trim();

export const numberConvertToInteger = (angka,fixed = 0) => {
    var rupiah = '';		
	var angkarev = parseInt(angka).toString().split('').reverse().join('');
	for(var i = 0; i < angkarev.length; i++) if(i%3 == 0) rupiah += angkarev.substr(i,3)+'.';
	return rupiah.split('',rupiah.length-1).reverse().join('');
}

export const numberRandom = (max,min) => (Math.random() * (max - min) + min);