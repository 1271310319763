import { createSlice } from "@reduxjs/toolkit";

const initialFollowupHistoryState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  followupHistoryForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const followupHistorySlice = createSlice({
  name: "followupHistory",
  initialState: initialFollowupHistoryState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		followupHistoryFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		followupHistoryAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    followupHistoryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.followupHistory.push(action.payload.followupHistory);
    },
    followupHistoryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.followupHistory.filter(el => el.follow_up_id !== action.payload.id);
    },
    getFollowupHistory: (state, action) => {
      state.actionsLoading = false;
      state.followupHistoryForEdit = action.payload.followupHistoryForEdit;
      state.error = null;
    },
    // updateProduct
    followupHistoryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.followupHistory = state.entities.followupHistory.map(entity => {
        if (entity.follow_up_id === action.payload.followupHistory.follow_up_id) {
          return action.payload.followupHistory;
        }
        return entity;
      });
    },
  },
});
